<template>
  <div class="home">
    <div class="gd-cont">
      <van-swipe @change="onChange">
        <van-swipe-item v-for="item in list" :key="item.id" class="gd-item" @click="goAdLink(item.link)">
          <div class="img"><img :src="item.img_url"></div>
          <div class="gd-current">
            <div class="title">{{ list[current].name }}</div>
            <div class="more">了解更多</div>
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="gd-tag">
            <div v-for="(item, index) in list" :key="item.id" class="item" :class="{selected: current==index}" />
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="product">
      <div class="head">
        <div class="title">您需要什么设备？</div>
        <div class="des">找到适合您的设备</div>
      </div>
      <div class="list">
        <van-swipe :loop="false" :show-indicators="false" :width="150">
          <van-swipe-item v-for="item in productList" :key="item.id" class="item" style="width:100px; padding: 10px 20px; margin-right: 10px;" @click="goProLink(item.id)">
            <div class="pic">
              <img :src="item.imgUrl">
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="more">查看更多</div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div v-for="item in homeAdList" :key="item.id" class="gd-2" @click="goAdLink(item.link)">
      <img :src="item.img_url">
    </div>
    <div class="other-list">
      <div class="item" :style="'background: url('+other1+')'" @click="goMorePro">
        <div class="pic"><img src="@/assets/more_icon.png"></div>
        <div class="title">查看更多产品</div>
        <div class="des">See more products</div>
      </div>
      <div class="item" :style="'background: url('+other2+')'" @click="goSuppor">
        <div class="pic"><img src="@/assets/technical_icon.png"></div>
        <div class="title">技术支持</div>
        <div class="des">Technical support</div>
      </div>
    </div>
    <div class="footer">
      <div class="menut-list">
        <div v-for="item in aboutList" :key="item.id" class="item" @click="goAboutDetail(item.id)">{{ item.name }}</div>
      </div>
      <div class="info">Copyright © 2020 版权为微星科技股份有限公司所有</div>
    </div>
  </div>
</template>

<script>
import pro1 from '@/assets/product_1.png'
import pro2 from '@/assets/product_2.png'
import pro3 from '@/assets/product_3.png'
import pro4 from '@/assets/product_4.png'
import other1 from '@/assets/moreImg.png'
import other2 from '@/assets/support.png'
import { getList } from '@/api/index'
export default {
  data() {
    return {
      list: [],
      current: 0,
      productList: [
        { id: 9, imgUrl: pro1, title: '主板' },
        { id: 11, imgUrl: pro2, title: '显示器' },
        { id: 10, imgUrl: pro3, title: '主机' },
        { id: 13, imgUrl: pro4, title: '机电扇' }
      ],
      aboutList: [],
      homeAdList: [],
      other1,
      other2
    }
  },
  mounted() {
    this.getAdList()
    this.getHomeAdList()
    this.getAboutList()
  },
  methods: {
    onChange(index) {
      this.current = index
    },
    goAdLink(url) {
      if (!url) return
      window.location.href = url
    },
    goMorePro() {
      this.$store.dispatch('setMenuStatus', true)
      this.$router.push({ path: '/product', query: { cateId: 9 }})
    },
    goSuppor() {
      window.location.href = 'https://cn.msi.com/support'
    },
    goProLink(cateId) {
      this.$router.push({ path: '/product', query: { cateId }})
    },
    goAboutDetail(id) {
      this.$router.push({ path: '/about', query: { id }})
    },
    async getAdList() {
      const res = await getList('advertisement', { flag: 'banner' })
      this.list = res.data
    },
    async getHomeAdList() {
      const res = await getList('advertisement', { flag: 'home' })
      this.homeAdList = res.data
    },
    async getAboutList() {
      const res = await getList('about_msi')
      this.aboutList = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .gd-cont {
    position: relative;
    .gd-item {
      font-size: 0;
      img {
        width: 100%;
        height: 170px;
      }
    }
    .gd-current {
      padding: 10px 10px 20px 10px;
      color: #fff;
      text-align: left;
      background-color: #666;
      .title {
        font-size: 16px;
      }
      .des {
        padding-top: 10px;
        font-size: 12px;
      }
      .more {
        display: block;
        width: 50px;
        padding: 4px 0;
        margin-top: 10px;
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        line-height: 1;
        background-color: #FF0000;
      }
    }
    .gd-tag {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 5px 0 10px 0;
      text-align: center;
      .item {
        display: inline-block;
        width: 15px;
        height: 1px;
        margin: 0 2px;
        background-color: #FFFFFF;
      }
      .selected {
        background-color: #FF0000;
      }
    }
  }
  .product {
    padding: 30px 0;
    color: #fff;
    text-align: center;
    background-color: #000000;
    .head {
      .title {
        font-size: 14px;
      }
      .des {
        padding-top: 10px;
        font-size: 10px;
      }
    }
    .list {
      padding: 20px 15px 0 15px;
      overflow: auto;
      .item {
        display: inline-block;
        width: 100px;
        border: 1px solid #212121;
        background-color: #111111;
        .pic {
          img {
            width: 65px;
            height: 65px;
          }
        }
        .title {
          padding-top: 10px;
          padding-bottom: 8px;
          font-size: 12px;
        }
        .more {
          padding-top: 8px;
          font-size: 8px;
          border-top: 1px solid #212121;
          color: #999999;
        }
      }
    }
  }
  .gd-2 {
    img {
      width: 100%;
    }
  }
  .other-list {
    display: flex;
    text-align: center;
    .item {
      flex: 1;
      padding: 80px 0;
      .pic {
        img {
          width: 30px;
          height: 30px;
        }
      }
      .title {
        padding-top: 8px;
        color: #fff;
        font-size: 12px;
      }
      .des {
        padding-top: 4px;
        font-size: 10px;
        color: #BFBFBF;
      }
    }
  }
  .footer {
    background: linear-gradient(0deg, #0F0F0F 0%, #222222 100%);
    .menut-list {
      padding: 0 15px;
      .item {
        padding: 12px 0;
        font-size: 14px;
        text-align: left;
        border-bottom: 1px solid #262626;
      }
    }
    .info {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
